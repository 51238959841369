<template>
  <div>
    <div class="grid grid-cols-1 gap-12">
      <div class="intro-y box p-5 mt-5">
        <Toast />
        <DataTable :value="services" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                   :rows="10" dataKey="id" :rowHover="true" v-model:selection="selectedServices" :filters="filters" :loading="loading">
          <template #header>
            <div class="table-header">
              Servicios
              <span class="p-input-icon-left">
							<i class="pi pi-search"/>
							<InputText v-model="filters['global'].value" placeholder="Search"/>
						</span>
            </div>
          </template>

          <template #empty>
            Servicios no disponibles.
          </template>
          <template #loading>
            Cargando datos de servicios. Por favor espera.
          </template>

          <Column field="solicitudServicio" header="Solicitud" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Solicitud</span>
              {{slotProps.data.solicitudServicio}}
            </template>
          </Column>
          <Column field="cliente" header="Cliente" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Cliente</span>
              {{slotProps.data.cliente}}
            </template>
          </Column>
          <Column field="tipoServicio" header="Tipo de servicio" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Tipo de servicio</span>
              {{slotProps.data.tipoServicio}}
            </template>
          </Column>
          <Column field="ciudadOrigen" header="Ciudad de origen" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Ciudad de origen</span>
              {{slotProps.data.ciudadOrigen}}
            </template>
          </Column>
          <Column field="ciudadDestino" header="Ciudad de destino" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Ciudad de destino</span>
              {{slotProps.data.ciudadDestino}}
            </template>
          </Column>
          <Column field="licitacionFleteValor" header="Valor flete" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Valor flete</span>
              {{slotProps.data.licitacionFleteValor}}
            </template>
          </Column>
          <Column headerStyle="width: 8rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
            <template #body="slotProps">
              <div class="flex items-center justify-center">
                <a class="flex items-center mr-3" href="javascript:;" @click="showTrazabilidad(slotProps.data)">
                  <EyeIcon class="w-6 h-6 mr-4" />
                </a>
                <a class="flex items-center mr-3" href="javascript:;" v-if="slotProps.data.faltantes>0" @click="showForm(slotProps.data)">
                  <TrendingUpIcon class="w-6 h-6 mr-4" />
                </a>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

  </div>
</template>

<script>
import AcceptedOrder from '../service/AcceptedOrder';
import {FilterMatchMode} from 'primevue/api';

export default {
  data() {
    return {
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      },
      loading: true,
      services: [],
      selectedServices: null,
      service: {},
      expandedRows: [],
      submitted: false
    }
  },
  AcceptedOrder: null,
  created() {
    this.AcceptedOrder = new AcceptedOrder();
  },
  mounted() {
    this.AcceptedOrder.getOrders().then(data => {
      this.services = Object.values(data);
      this.loading = false;
    })
        .catch(() => {
          this.$toast.add({severity:'error', summary: 'Error al traer los datos', detail:'Error al traer los datos', life: 5000});
          this.loading = false;
        });
  },
  methods: {
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.services.length; i++) {
        if (this.services[i].solicitudServicio === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    showForm(service){
      var id = service.id;
      this.$router.push({ name: 'generateorder', params: { id } })
    },
    showTrazabilidad(service){
      var id = service.id;
      this.$router.push({ name: 'vieworder', params: { id } })
    }
  }
}
</script>

<style scoped lang="scss">
.p-component{
  font-size: 0.875rem !important;
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

.p-datatable .p-column-filter {
  display: none;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }

  .p-paginator {
    padding: 1rem;
  }

  .p-datatable-thead > tr > th {
    text-align: left;
  }

  .p-datatable-tbody > tr > td {
    cursor: auto;
  }

  .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
  }
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-qualified {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-unqualified {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-negotiation {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.status-new {
    background: #B3E5FC;
    color: #23547B;
  }

  &.status-renewal {
    background: #ECCFFF;
    color: #694382;
  }

  &.status-proposal {
    background: #FFD8B2;
    color: #805B36;
  }
}

.p-progressbar-value.ui-widget-header {
  background: #607d8b;
}

@media (max-width: 640px) {
  .p-progressbar {
    margin-top: .5rem;
  }
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
  padding: 1rem;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-instock {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.order-delivered {
    background: #C8E6C9;
    color: #256029;
  }

  &.order-cancelled {
    background: #FFCDD2;
    color: #C63737;
  }

  &.order-pending {
    background: #FEEDAF;
    color: #8A5340;
  }

  &.order-returned {
    background: #ECCFFF;
    color: #694382;
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        > td {
          text-align: left;
          display: block;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: .4rem;
            min-width: 30%;
            display: inline-block;
            margin: -.4rem 1rem -.4rem -.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: .5rem;
          }
        }
      }
    }
  }
}
</style>
